































.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.required-label:after {
  content: "*";
  margin-left: 0.4rem;
  color: rgb(240, 86, 115);
}
