// UIKit Theme Customizations (see https://getuikit.com/docs/sass)

// 1. Your custom variables and variable overwrites in ./theme/_variables.scss
// @import "theme/variables";
// $global-link-color: #DA7D02;

// 2. Import default variables and available mixins.
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
//@import "theme/accordion-mixins.scss";

// 4. Import UIkit.
@import "~uikit/src/scss/uikit-theme.scss";

*{
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: rgb(220, 241, 248);
  margin : 0;
  min-height : 100%;
  display: flex;
  flex-direction: column;
}

.fa-gitlab {
  padding: 0.5rem 0.5rem;
}